<template>
  <div v-if="isVisible" class="redirect-popup">
    <div class="redirect-popup__content">
      <div class="redirect-popup__loading">
        <div class="dots-loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <p class="redirect-popup__text">
        Você está sendo redirecionado para o {{ redirectText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MRedirectPopup",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    redirectText: {
      type: String,
      default: "sistema"
    }
  }
};
</script>

<style lang="scss" scoped>
.redirect-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  &__content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  &__loading {
    margin-bottom: 1rem;
  }

  &__text {
    color: #333;
    font-size: 1.1rem;
  }
}

.dots-loading {
  display: flex;
  justify-content: center;
  gap: 6px;

  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #78bbef;
    animation: dots 1.5s infinite ease-in-out;

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.1}s;
      }
    }
  }
}

@keyframes dots {
  0%,
  100% {
    transform: scale(0.3);
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
